// @flow strict-local
import type {Year} from './GenericTypes';

export type AwardData = {|
  title: string,
  link?: string,
  note?: string,
  year: Year,
|}

const AWARDS: Array<AwardData> = [
  // {
  //  title: "Google Research Scholar Program Award",
  //  year: 2022,
  //  link: "https://research.google/outreach/research-scholar-program/"
  //},
  {
    title: "ACM SIGSAC Outstanding Early-Career Researcher Award for exceptional contributions to the field of computer security and privacy",
    year: 2024,
    link: "https://www.sigsac.org/award/early-career-award.html"
  },
  {
    title: "3x USENIX Security Distinguished Paper Awards (2021, 2020 & 2018)",
    year: 2021,
  },
  {
    title: "2x ACM Human Factors in Computing Systems (CHI) Honorable Mention (Top 5%) Awards (2022 & 2023)",
    year: 2023,
  },
  {
    title: "ACM Computer Supported Cooperative Work (CSCW) Diversity & Inclusion Recognition",
    year: 2022,
    link: "https://cscw.acm.org/2022/awards/"
  },
   {
    title: "ACM EAAMO New Horizons Paper Award",
    year: 2022,
    link: "https://eaamo.org/awards/"
  },
  {
    title: "ACM Computer & Communications Security (CCS) Best Paper Runner Up Award",
    year: 2021,
    link: "https://sigsac.org/ccs/CCS2021/ccs-awards.html"
  },
    {
    title: "Google Research Scholar Award",
    year: 2022,
    link: "https://research.google/outreach/research-scholar-program/recipients/"
  },
  {
    title: "Facebook Research Award: Economic Opportunity and Digital Platforms",
    year: 2019,
    link: "https://research.fb.com/blog/2019/11/announcing-the-winners-of-the-economic-opportunity-and-digital-platforms-request-for-proposals/"
  },
  {
    title: "John Karat Usable Privacy and Security Student Research Award",
    year: 2018,
    link: "http://www.cs.umd.edu/article/2018/08/elissa-redmiles-receives-john-karat-usable-privacy-and-security-student-research",
    note: "Awarded annually to the top graduate student in usable security and privacy",
  },
 // {
  //  title: "USENIX Security 2018 Distinguished Paper Award",
 //   year: 2018,
//  },
  {
    title: "NSF Graduate Research Fellowship (NSF GRFP)",
    year: 2017,
    link: "https://www.nsfgrfp.org"
  },
  {
    title: "National Defense Science and Engineering Graduate Fellowship (NDSEG)",
    year: 2017,
    link: "https://ndseg.asee.org/",
  },
  {
    title: "Facebook Fellowship",
    year: 2017,
    link: "https://research.fb.com/programs/fellowship",
  },
];

export {
  AWARDS,
}
